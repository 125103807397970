import { create } from 'zustand';

interface AlertState {
  isalertOpen: boolean;
  alertContent: string;
  closeText?: string | null;
  confirmText: string | null;
  confirmEvent?: any;
  cancelEvent: null;
  requireInput: boolean; // 추가된 필드: input 사용 여부를 제어
  setConfirmEvent: any;
  setCancelEvent: any;
  inputText: string;
  openAlert: (
    text: string,
    confirmTextProps?: string,
    closeTextProps?: string,
    confirmEventProps?: any,
    requireInputProps?: boolean, // input 사용 여부를 설정
    cancelEventProps?: any
  ) => void;
  setInputText: (e: string) => void;
  closeAlert: () => void;
}

export const alertStore = create<AlertState>((set) => ({
  isalertOpen: false,
  alertContent: null,
  closeText: null,
  inputText: '',
  confirmText: null,
  confirmEvent: null,
  cancelEvent: null,
  requireInput: false, // 초기값 설정
  setInputText: (e) => set({ inputText: e }),
  setConfirmEvent: (event) =>
    set({
      confirmEvent: event,
    }),
  setCancelEvent: (event) =>
    set({
      cancelEvent: event,
    }),
  openAlert: (
    text,
    confirmTextProps,
    closeTextProps,
    confirmEventProps,
    requireInputProps = false, // 기본값: false
    cancelEventProps
  ) =>
    set({
      isalertOpen: true,
      alertContent: text,
      confirmText: confirmTextProps,
      closeText: closeTextProps,
      confirmEvent: confirmEventProps,
      cancelEvent: cancelEventProps,
      requireInput: requireInputProps, // requireInput 설정
    }),
  closeAlert: () =>
    set({
      isalertOpen: false,
      alertContent: null,
      confirmText: null,
      confirmEvent: null,
      closeText: null,
      cancelEvent: null,
      requireInput: false, // 초기화
    }),
}));
