import { CssVarsProvider } from '@mui/joy/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import Alert from './components/Util/Alert/Alert';
import ErrorBoundaryContext from './components/Util/Error/ErrorBoundaryContext';
import PopUp from './components/Util/PopUp/PopUp';
import ScrollToTop from './components/Util/ScrollToTop';
import { errorBar } from './components/Util/SnackBar/SnackBar';
import { theme } from './styles/muiTheme';
import './styles/scss/front.scss';
import { StyleTheme } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const queryErrorHandler = (error) => {
  errorBar(`데이터를 불러오지 못했습니다. ${error.message}`);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retryOnMount: true,
      refetchIntervalInBackground: true,
      refetchOnReconnect: true,
      staleTime: 1000 * 60,
      cacheTime: 1000 * 60 * 5,
      retry: 0,
      onError: queryErrorHandler,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    <CssVarsProvider theme={theme}>
      <ThemeProvider theme={StyleTheme}>
        <ErrorBoundaryContext>
          <BrowserRouter>
            <ScrollToTop />
            <App />
            <PopUp />
            <Alert />
            <Toaster />
          </BrowserRouter>
        </ErrorBoundaryContext>
      </ThemeProvider>
    </CssVarsProvider>
  </QueryClientProvider>
);
