export const LNBMenuList = [
  {
    link: 'basic',
    title: '기본 상품 관리',
  },
  {
    link: 'market',
    title: '마켓 상품 관리',
  },
  // {
  //   link: 'category',
  //   title: '카테고리 관리',
  // },
  {
    link: 'product',
    title: '상품 관리 설정',
  },
];
