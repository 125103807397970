import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import instance from '../../apis/Request';
import commonStore from '../../stores/commonStore';
import solutionStore from '../../stores/solutionStore';
import userStore from '../../stores/userStore';
import IntroLayoutFooter from './intro/IntroLayoutFooter';
import IntroLayoutHeader from './intro/IntroLayoutHeader';

const IntroLayoutComponent = () => {
  const location = useLocation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasBg, setHasBg] = useState<boolean>(false);
  const { userSession } = userStore().getUserInfo();
  const { setMarkets, setSites } = commonStore();
  const { setSolutionList, setChargeDays, setUserSolution, setUserPreviousSolution } = solutionStore();

  const getFrontOfficeDefaultInfo = useCallback(async () => {
    try {
      const { data: marketData } = await instance.get('/common/store');
      setMarkets(marketData.responseData.inquiryResult);

      const { data: siteData } = await instance.get('/common/collectionsite');
      setSites(siteData.responseData.inquiryResult);

      const { data: solutionData } = await instance.get('/solution/productlist');
      setSolutionList(solutionData?.responseData.inquiryResult);
      setChargeDays(solutionData?.responseData.useDaysResult);

      const { data: userSolutionData } = await instance.get('/solution/me');
      if (userSolutionData?.responseData.inquiryResult.length > 0) {
        setUserSolution(userSolutionData?.responseData.inquiryResult[0] || null);
      }
      if (userSolutionData?.responseData.previousSolution.length > 0) {
        setUserPreviousSolution(userSolutionData?.responseData.previousSolution[0] || null);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [setMarkets, setSites, setSolutionList, setChargeDays, setUserSolution, setUserPreviousSolution]);

  useEffect(() => {
    if (userSession !== '') {
      getFrontOfficeDefaultInfo();
    }
  }, [getFrontOfficeDefaultInfo, userSession]);

  useEffect(() => {
    const hasBgPaths = [
      '/member/register-solution',
      '/member/register-solution/',
      '/member/register-solution/step-1',
      '/member/register-solution/step-2',
      '/member/register-solution/step-3',
      '/member/register-solution/step-4',
    ];
    if (hasBgPaths.includes(location.pathname)) {
      setHasBg(true);
    } else {
      setHasBg(false);
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="market fo">
      <IntroLayoutHeader />
      <div className={`${hasBg ? 'contents-wrap has-bg' : 'contents-wrap'}`}>
        <Outlet />
        <div className="fixed-wrap">
          {/*  채팅솔루션 적용 */}
          {/* <ChatButton classname={'fixed-btn chat'} /> */}
          <button onClick={scrollToTop} className="fixed-btn go-top"></button>
        </div>
      </div>
      <IntroLayoutFooter />
    </div>
  );
};

export default IntroLayoutComponent;
