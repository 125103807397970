import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import solutionStore from '../../../stores/solutionStore';
import userStore from '../../../stores/userStore';
import { infoBar, successBar } from '../../Util/SnackBar/SnackBar';

const SellerOfficeLLayoutHeader = () => {
  const { unsetLogin } = userStore();
  const { unsetSolution } = solutionStore();
  const [selectedSearch, setSelectedSearch] = useState('');
  const handleChangeSelect = (_e: React.MouseEvent, value) => setSelectedSearch(value);

  const navigate = useNavigate();

  const logOut = () => {
    unsetSolution();
    unsetLogin();
    successBar('로그아웃 되었습니다.');
    navigate('/');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    infoBar('준비중입니다.');
    return;
  };

  return (
    <div className="header-space">
      <div className="head-left">
        <h1 className="logo">
          <Link to="/" title="첫 화면 바로가기">
            logo
          </Link>
        </h1>
      </div>
      <div className="flex">
        <div className="head-right">
          {/* <a href="#none" className="setting user">
            정보설정
          </a> */}
          <button type="button" onClick={logOut} className="logout user">
            로그아웃
          </button>

          {/* TODO : 가이드페이지url 추후 변경해야함 */}
          <a
            // href="https://savory-giver-e23.notion.site/bb11a73c22c14d46a77a555704f693bc"
            onClick={() => infoBar('준비중입니다.')}
            target="_blank"
            title="노션 사용자가이드 페이지로 이동"
            className="user-guide"
            rel="noreferrer">
            사용자가이드
          </a>
        </div>
        {/* <form onSubmit={handleSubmit} className="primary-bg">
          <Select
            placeholder={'선택하세요'}
            indicator={<KeyboardArrowDown sx={{ fontSize: 20 }} />}
            value={selectedSearch}
            onChange={handleChangeSelect}
            sx={{
              '::placeholder': {
                color: '#000',
              },
              ':hover': {
                background: 'transparent',
              },
              backgroundColor: 'transparent',
              border: 'none',
              width: '16rem',
              [`& .${selectClasses.indicator}`]: {
                transition: '0.2s',
                [`&.${selectClasses.expanded}`]: {
                  transform: 'rotate(-180deg)',
                },
              },
            }}>
            <Option value="orderNum">주문번호</Option>
            <Option value="productNum">상품명/상품코드</Option>
            <Option value="orderName">주문자/수령자명</Option>
            <Option value="postNum">운송장번호</Option>
          </Select>

          <div className="input-box">
            <input type="text" className="input" placeholder="검색어를 입력하세요" />
            <button className="search-ty2">검색</button>
          </div>
        </form> */}
      </div>
    </div>
  );
};

export default SellerOfficeLLayoutHeader;
